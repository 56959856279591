export const columns = [
    {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        align:'center',
        // ellipsis:true,
        fixed: 'left',
        scopedSlots: { customRender: 'name'}
    },
    {
        title: '商品图片',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
        /*width:150,*/
        ellipsis:true,
        align:'center',
        scopedSlots: { customRender: 'imgUrl'}
    },
    {
        title: '标签',
        dataIndex: 'tag',
        key: 'tag',
        width: 100,
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'name'}
    },
    {
        title: '分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width:120,
        align:'center',
      scopedSlots: { customRender: 'categoryName'}
    },
    {
        title: '品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
      title: '型号',
      dataIndex: 'barCode',
      key: 'barCode',
      width:150,
      align: 'center',
      ellipsis: true,
      // scopedSlots: {customRender: 'price'}
    },
    {
        title: '库存',
        dataIndex: 'stock',
        key: 'stock',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '审核状态',
        dataIndex: 'flowStatus',
        key: 'flowStatus',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'flowStatus'}
    },
    {
        title: '标准开票价（D价）',
        dataIndex: 'sellPrice',
        key: 'sellPrice',
        width:150,
        align: 'center',
        ellipsis: true,
        scopedSlots: {customRender: 'price'}
    },
    {
        title: '上架状态',
        dataIndex: 'flagUp',
        key: 'flagUp',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'flagUp'}
    },
    {
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'createTime',
        width:200,
        align:'center',
        ellipsis:true
    },
    // {
    //     title: '操作',
    //     dataIndex: 'action',
    //     key: 'action',
    //     width:150,
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'action'}
    // },
]

export const Repertorcolumns = [
    {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        align:'center',
        ellipsis:true,
        // width: 300,
        // fixed: 'left',
        scopedSlots: { customRender: 'name'}
    },
    {
        title: '商品图片',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
        width:150,
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'imgUrl'}
    },
    {
        title: '规格型号',
        dataIndex: 'specStr',
        key: 'specStr',
        /*width:150,*/
        width:150,
        align:'center',
        ellipsis:true
    },
    {
        title: '分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        /*width:150,*/
        width:150,
        align:'center',
        ellipsis:true
    },
    {
        title: '品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        /*width:150,*/
        width:150,
        align:'center',
        ellipsis:true
    },
    {
        title: '单位',
        dataIndex: 'proUnitName',
        key: 'proUnitName',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '订单占用库存',
        dataIndex: 'orderOccupyRep',
        key: 'orderOccupyRep',
        /*width:150,*/
        width:150,
        align:'center',
        ellipsis:true
    },
    {
        title: '库存',
        dataIndex: 'realQuantity',
        key: 'realQuantity',
        /*width:150,*/
        width:150,
        align:'center',
        ellipsis:true,
        fixed: 'right',
        scopedSlots: { customRender: 'realQuantity'}
    },
]